jQuery(function ($) {
    $(document).ready(function () {
        //set inner content same height as taller
        if ($(window).width() > 991) {
            consolidateInnerCtnHeight();
            $( window ).resize(consolidateInnerCtnHeight);
        }

        let mobile = false;

        $(window).load(function() {
            checkMobile(); // check if mobile
            consolidateInnerCtnHeight(); //set inner content same height as taller
        });

        $( window ).resize(function() {
            checkMobile();
            consolidateInnerCtnHeight();
            console.log('resize');
        });

        // check if mobile
        function checkMobile() {
            
            let mobileBreakPoint = 752; // change accordingly

            if( $(window).width() < mobileBreakPoint ) {
                mobile = true;
            }
        }

        // equal height func
        function consolidateInnerCtnHeight(){
            if( !mobile ) {
                $(".def6-block.card-image").each(function () {
                    var innerCntHeight = 0;
                    $(this).find('.inner-ctn').height('unset');
                    $(this).find('.inner-ctn').each(function () {
                        console.log($(this).height());
                        if ($(this).height() > innerCntHeight)
                            innerCntHeight = $(this).height();
                    });
                    $(this).find('.inner-ctn').height(innerCntHeight);
                });
            }
        }
    });
});